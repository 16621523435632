import type { ThemeTypes } from '@/types/themeTypes/ThemeType';

const DARK_BLUE_THEME: ThemeTypes = {
  name: 'DARK_BLUE_THEME',
  dark: true,
  variables: {
    'border-color': '#333F55',
    'border-opacity': 1
  },
  colors: {
    primary: '#1e4db7',
    secondary: '#1a97f5',
    info: '#539BFF',
    success: '#13DEB9',
    accent: '#FA896B',
    warning: '#FFAE1F',
    error: '#FA896B',
    lightprimary: '#253662',
    lightsecondary: '#1C455D',
    lightsuccess: '#1B3C48',
    lighterror: '#4B313D',
    lightwarning: '#4D3A2A',
    lightinfo:'#223662',
    textPrimary: '#EAEFF4',
    textSecondary: '#7C8FAC',
    borderColor: '#333F55',
    inputBorder: '#465670',
    containerBg: '#2a3447',
    background: '#2c3952',
    hoverColor: '#333f55',
    surface: '#2a3447',
    'on-surface-variant': '#2a3447',
    grey100: '#333F55',
    grey200: '#465670',
    hungry: '#df6c49',
    sleepy: '#497ddf',
    needstoburp: '#dfd549',
    uncomfortable: '#6bae42',
    irritated: '#c95ec1'
  }
};

const DARK_RED_THEME: ThemeTypes = {
  name: 'DARK_RED_THEME',
  dark: true,
  variables: {
    'border-color': '#333F55',
    'border-opacity': 1
  },
  colors: {
    primary: '#5e244d',
    secondary: '#ff5c8e',
    info: '#539BFF',
    success: '#13DEB9',
    accent: '#FA896B',
    warning: '#FFAE1F',
    error: '#FA896B',
    lightprimary: '#fce6ed',
    lightsecondary: '#0C4339',
    lightsuccess: '#1B3C48',
    lighterror: '#4B313D',
    lightwarning: '#4D3A2A',
    lightinfo:'#223662',
    textPrimary: '#EAEFF4',
    textSecondary: '#7C8FAC',
    borderColor: '#333F55',
    inputBorder: '#465670',
    containerBg: '#171c23',
    background: '#171c23',
    hoverColor: '#333f55',
    surface: '#171c23',
    'on-surface-variant': '#171c23',
    grey100: '#333F55',
    grey200: '#465670',
    hungry: '#df6c49',
    sleepy: '#497ddf',
    needstoburp: '#dfd549',
    uncomfortable: '#6bae42',
    irritated: '#c95ec1'
  }
};

const DARK_PURPLE_THEME: ThemeTypes = {
  name: 'DARK_PURPLE_THEME',
  dark: true,
  variables: {
    'border-color': '#333F55',
    'border-opacity': 1
  },
  colors: {
    primary: '#402e8d',
    secondary: '#7352ff',
    info: '#539BFF',
    success: '#13DEB9',
    accent: '#FA896B',
    warning: '#FFAE1F',
    error: '#FA896B',
    lightprimary: '#26153C',
    lightsecondary: '#09454B',
    lightsuccess: '#1B3C48',
    lighterror: '#4B313D',
    lightwarning: '#4D3A2A',
    lightinfo:'#223662',
    textPrimary: '#EAEFF4',
    textSecondary: '#7C8FAC',
    borderColor: '#333F55',
    inputBorder: '#465670',
    containerBg: '#171c23',
    background: '#171c23',
    hoverColor: '#333f55',
    surface: '#171c23',
    'on-surface-variant': '#171c23',
    grey100: '#333F55',
    grey200: '#465670',
    hungry: '#df6c49',
    sleepy: '#497ddf',
    needstoburp: '#dfd549',
    uncomfortable: '#6bae42',
    irritated: '#c95ec1'
  }
};

const DARK_GREEN_THEME: ThemeTypes = {
  name: 'DARK_GREEN_THEME',
  dark: true,
  variables: {
    'border-color': '#333F55',
    'border-opacity': 1
  },
  colors: {
    primary: '#066a73',
    secondary: '#00cec3',
    info: '#539BFF',
    success: '#13DEB9',
    accent: '#FA896B',
    warning: '#FFAE1F',
    error: '#FA896B',
    lightprimary: '#05313F',
    lightsecondary: '#282917',
    lightsuccess: '#1B3C48',
    lighterror: '#4B313D',
    lightwarning: '#4D3A2A',
    lightinfo:'#223662',
    textPrimary: '#EAEFF4',
    textSecondary: '#7C8FAC',
    borderColor: '#333F55',
    inputBorder: '#465670',
    containerBg: '#171c23',
    background: '#171c23',
    hoverColor: '#333f55',
    surface: '#171c23',
    'on-surface-variant': '#171c23',
    grey100: '#333F55',
    grey200: '#465670',
    hungry: '#df6c49',
    sleepy: '#497ddf',
    needstoburp: '#dfd549',
    uncomfortable: '#6bae42',
    irritated: '#c95ec1'
  }
};

const DARK_INDIGO_THEME: ThemeTypes = {
  name: 'DARK_INDIGO_THEME',
  dark: true,
  variables: {
    'border-color': '#333F55',
    'border-opacity': 1
  },
  colors: {
    primary: '#11397b',
    secondary: '#1e4db7',
    info: '#539BFF',
    success: '#13DEB9',
    accent: '#FA896B',
    warning: '#FFAE1F',
    error: '#FA896B',
    lightprimary: '#003638',
    lightsecondary: '#40241C',
    lightsuccess: '#1B3C48',
    lighterror: '#4B313D',
    lightwarning: '#4D3A2A',
    lightinfo:'#223662',
    textPrimary: '#FFFFFF',
    textSecondary: '#EEEEEE',
    borderColor: '#333F55',
    inputBorder: '#465670',
    containerBg: '#171c23',
    background: '#1f2630',
    hoverColor: '#333f55',
    surface: '#171c23',
    'on-surface-variant': '#171c23',
    grey100: '#333F55',
    grey200: '#465670',
    hungry: '#df6c49',
    sleepy: '#497ddf',
    needstoburp: '#dfd549',
    uncomfortable: '#6bae42',
    irritated: '#c95ec1'
  }
};

const DARK_ORANGE_THEME: ThemeTypes = {
  name: 'DARK_ORANGE_THEME',
  dark: true,
  variables: {
    'border-color': '#333F55',
    'border-opacity': 1
  },
  colors: {
    primary: '#fb9678',
    secondary: '#03c9d7',
    info: '#539BFF',
    success: '#13DEB9',
    accent: '#FA896B',
    warning: '#FFAE1F',
    error: '#FA896B',
    lightprimary: '#e5fafb',
    lightsecondary: '#082E45',
    lightsuccess: '#1B3C48',
    lighterror: '#4B313D',
    lightwarning: '#4D3A2A',
    lightinfo:'#223662',
    textPrimary: '#EAEFF4',
    textSecondary: '#7C8FAC',
    borderColor: '#333F55',
    inputBorder: '#465670',
    containerBg: '#171c23',
    background: '#171c23',
    hoverColor: '#333f55',
    surface: '#171c23',
    'on-surface-variant': '#171c23',
    grey100: '#333F55',
    grey200: '#465670',
    hungry: '#df6c49',
    sleepy: '#497ddf',
    needstoburp: '#dfd549',
    uncomfortable: '#6bae42',
    irritated: '#c95ec1'
  }
};

export {
  DARK_BLUE_THEME, DARK_RED_THEME, DARK_ORANGE_THEME, DARK_PURPLE_THEME, DARK_GREEN_THEME, DARK_INDIGO_THEME
};
