import BaseRequest from './base';
import type { BaseResponse } from './base';

export interface AccountResponse {
  sub: string;
  email: string;
  baby_name : string;
  name: string;
}

class AccountRequest extends BaseRequest {
  readonly endpoint: string = "account";

  constructor() {
    super();
  }

  async get(sub: string): Promise<BaseResponse<AccountResponse >> {
    return await this._get(`${super.address}/${this.endpoint}/${sub}`);
  }

  async update(sub: string, accountArgs: { baby_name: string, name: string }): Promise<BaseResponse<AccountResponse >> {
    return await this._patch(`${super.address}/${this.endpoint}/${sub}`, accountArgs);
  }
}

const accountRequest = new AccountRequest();

export default accountRequest;
