import mock from '../../mockAdapter';
import { Chance } from 'chance';
import type { ChatType } from '@/types/apps/ChatType';
import { sub } from 'date-fns';
import { uniqueId } from 'lodash';

import user1 from '@/assets/images/profile/user-2.jpg';
import user2 from '@/assets/images/profile/user-3.jpg';
import user3 from '@/assets/images/profile/user-4.jpg';
import user4 from '@/assets/images/profile/user-5.jpg';
import user5 from '@/assets/images/profile/user-6.jpg';

import background1 from '@/assets/images/blog/blog-img5.jpg';

import adobe from '@/assets/images/chat/icon-adobe.svg';
import chrome from '@/assets/images/chat/icon-chrome.svg';
import figma from '@/assets/images/chat/icon-figma.svg';
import java from '@/assets/images/chat/icon-javascript.svg';
import zip from '@/assets/images/chat/icon-zip-folder.svg';

const chance = new Chance();

// const ChatData: ChatTypes[] = [
const ChatData: ChatType[] = [{
  id: 1,
  name: 'Teddy',
  status: 'online',
  thumb: user1,
  recent: false,
  chatHistory: [
    {
      createdAt: sub(new Date(), { hours: 1 }),
      msg: 'Hello! My baby is crying a lot and I don\'t know what to do',
      senderId: 1,
      type: 'text',
      attachment: [
        {
          icon: adobe, file: 'service-task.pdf', fileSize: '2MB'
        },
        {
          icon: chrome, file: 'homepage-design.fig', fileSize: '3MB'
        },
        {
          icon: figma, file: 'about-us.htmlf', fileSize: '1KB'
        },
        {
          icon: java, file: 'work-project.zip', fileSize: '20MB'
        },
        {
          icon: zip, file: 'custom.js', fileSize: '2MB'
        }
      ],
      id: uniqueId()
    },
    {
      createdAt: sub(new Date(), { minutes: 6 }),
      msg: 'I\'m sorry to hear that your baby is crying a lot. It\'s completely normal to feel concerned when your baby is upset. Babies cry for various reasons, and it can sometimes take a bit of detective work to figure out what\'s causing it. You\'re doing a great job by seeking help and caring for your baby. How many months or years old is your child? Once you tell me, I can provide a more detailed answer.',
      senderId: uniqueId(),
      type: 'text',
      attachment: [],
      id: uniqueId()
    },

    {
      createdAt: sub(new Date(), { minutes: 5 }),
      msg: "He's 5 weeks old.",
      senderId: 1,
      type: 'text',
      attachment: [],
      id: uniqueId()
    },
    {
      createdAt: sub(new Date(), { minutes: 5 }),
      msg: 'Of course. For a 5-week-old baby, crying can be due to hunger, needing a diaper change, tiredness, gas, or wanting comfort. Try feeding your baby, changing their diaper, swaddling them, or using gentle rocking. Skin-to-skin contact and a pacifier can also help soothe them.\n\nIf the crying continues or you\'re worried, it\'s always a good idea to consult your pediatrician.',
      senderId: uniqueId(),
      type: 'text',
      attachment: [],
      id: uniqueId()
    }
  ]
}];

mock.onGet('/api/data/chat/ChatData').reply(() => {
  return [
    200, ChatData
  ];
});

export default ChatData;
