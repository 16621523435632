import BaseRequest from './base';

import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';

const baseUrl = import.meta.env.VITE_API_URL ?? (window.location.origin + '/api');

export interface AuthRequestResponse {
  sub: string;
  username: string;
  email: string;
  token: string;
  baby_name : string;
  name: string;
}

class AuthRequest extends BaseRequest {
  readonly endpoint: string = "account";

  constructor() {
    super();
  }

  async login(username: string, password: string, device?: string): Promise<AuthRequestResponse> {
    return await fetchWrapper.post(`${baseUrl}/auth/login`, {
      username, password, device
    });
  }

  async sessionLogin(): Promise<AuthRequestResponse> {
    return await fetchWrapper.post(`${baseUrl}/auth/tokenLogin`, {});
  }

  async tokenLogin(token: string, device?: string): Promise<AuthRequestResponse> {
    const postData = device ? { token, device } : { token };

    return await fetchWrapper.post(`${baseUrl}/auth/tokenLogin`, postData);
  }

  async logout(): Promise<AuthRequestResponse> {
    return await fetchWrapper.post(`${baseUrl}/auth/logout`, {});

  }
}

const authRequest = new AuthRequest();

export default authRequest;
