import { createRouter, createWebHistory } from 'vue-router';
import MainRoutes from './MainRoutes';
import AuthRoutes from './AuthRoutes';
import { useAuthStore } from '@/stores/auth';
import { useCookies } from 'vue3-cookies';
import { alert } from '@/stores/alert';


export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    MainRoutes,
    AuthRoutes
  ]
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  if (to.path == "/auth/login") {
    return next();
  }

  if (!authStore.accountIsLoggedIn) {
    const token = to.query.token as string;
    const { cookies } = useCookies();


    if(token) {
      const device = to.query.device as string;

      try {
        await authStore.tokenLogin(token, device);
      } catch (error) {
        alert.show('Invalid token');
        console.log("Session login failed, redirecting to login page");

        return next('/auth/login');
      }

      return next();
    } else if (cookies.get('connect.sid')) {
      try {
        await authStore.sessionLogin();

        next();
      } catch (error) {
        console.log("Session login failed, redirecting to login page");

        return next('/auth/login');
      }

      return next();
    } else {
      return next('/auth/login');
    }
  } else {
    return next();
  }
});
