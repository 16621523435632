import axios from 'axios';

export interface BaseResponseAttributes {
  status: number
  headers: object
}

export interface BaseResponse<T> extends BaseResponseAttributes {
  data: T;
}


export default class BaseRequest {
  get _stageAddress(): string {
    if (window.location.hostname === 'localhost') {
      return 'http://localhost:3000';
    } else {
      return window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;
    }
  }

  get _headers(): {headers: object} {

    return { headers: { 'Accept-Language': "en" } };
  }

  get _config(): {headers: object, withCredentials: boolean} {

    const config = { ...this._headers, withCredentials: true };

    return config;
  }

  get address() {
    return `${this._stageAddress}/api`;
  }

  async _get<T>(address: string): Promise<BaseResponse<T>> {
    return axios.get(address, this._config);
  }

  async _post<T>(address: string, payload?: object): Promise<BaseResponse<T>> {
    return axios.post(address, { ...payload }, this._config);
  }

  async _patch<T>(address: string, payload?: object): Promise<BaseResponse<T>> {
    return axios.patch(address, { ...payload }, this._config);
  }

  async _delete<T>(address: string): Promise<BaseResponse<T>> {
    return axios.delete(address, this._config);
  }
}
