import { Chance } from 'chance';
import { random } from 'lodash';
import { sub } from 'date-fns';
import mock from '../../mockAdapter';
import s1 from '@/assets/images/blog/blog-img1.jpg';
import s2 from '@/assets/images/blog/blog-img2.jpg';
import s3 from '@/assets/images/blog/blog-img3.jpg';
import s4 from '@/assets/images/blog/blog-img4.jpg';
import s5 from '@/assets/images/blog/blog-img5.jpg';
import s6 from '@/assets/images/blog/blog-img6.jpg';
import s7 from '@/assets/images/blog/blog-img7.jpg';
import s8 from '@/assets/images/blog/blog-img8.jpg';
import s9 from '@/assets/images/blog/blog-img9.jpg';
import s10 from '@/assets/images/blog/blog-img10.jpg';

import user1 from '@/assets/images/profile/user-1.jpg';
import user2 from '@/assets/images/profile/user-2.jpg';
import user3 from '@/assets/images/profile/user-3.jpg';
import user4 from '@/assets/images/profile/user-4.jpg';
import user5 from '@/assets/images/profile/user-5.jpg';
import user6 from '@/assets/images/profile/user-1.jpg';
import { uniqueId } from 'lodash';

import type { blogType, blogpostType } from '@/types/apps/BlogTypes';

const chance = new Chance();

const BlogComment: blogType[] = [
  {
    id: uniqueId('#comm_'),
    profile: {
      id: chance.integer({ min: 1, max: 2000 }),
      avatar: user2,
      name: chance.name()
    },
    time: chance.date(),
    comment: chance.paragraph({ sentences: 2 }),
    replies: []
  },
  {
    id: uniqueId('#comm_'),
    profile: {
      id: chance.integer({ min: 1, max: 2000 }),
      avatar: user3,
      name: chance.name()
    },
    time: chance.date(),
    comment: chance.paragraph({ sentences: 2 }),
    replies: [{
      id: uniqueId('#comm_'),
      profile: {
        id: chance.integer({ min: 1, max: 2000 }),
        avatar: user3,
        name: chance.name()
      },
      time: chance.date(),
      comment: chance.paragraph({ sentences: 2 })
    }]
  },
  {
    id: uniqueId('#comm_'),
    profile: {
      id: chance.integer({ min: 1, max: 2000 }),
      avatar: user4,
      name: chance.name()
    },
    time: chance.date(),
    comment: chance.paragraph({ sentences: 2 }),
    replies: []
  }
];

const BlogPost: blogpostType[] = [
  {
    id: chance.integer({ min: 1, max: 2000 }),
    title: 'Decoding the Tears: Different Types of Baby Cries Explained',
    content: chance.paragraph({ sentences: 2 }),
    coverImg: s1,
    createdAt: sub(new Date(), {
      days: 8, hours: 6, minutes: 20
    }),
    view: random(9999),
    share: random(9999),
    category: 'Science',
    featured: false,
    author: {
      id: chance.integer({ min: 1, max: 2000 }),
      avatar: user1,
      name: chance.name()
    },
    comments: BlogComment
  },
  {
    id: chance.integer({ min: 1, max: 2000 }),
    title: 'Nighttime Cries: Tips for Managing Baby\'s Sleep-Related Crying',
    content: chance.paragraph({ sentences: 2 }),
    coverImg: s2,
    createdAt: sub(new Date(), {
      days: 7, hours: 3, minutes: 20
    }),
    view: random(9999),
    share: random(9999),
    category: 'Parenting Tips',
    featured: false,
    author: {
      id: chance.integer({ min: 1, max: 2000 }),
      avatar: user2,
      name: chance.name()
    },
    comments: BlogComment
  },
  {
    id: chance.integer({ min: 1, max: 2000 }),
    title: 'The Science Behind Baby Cries: What Research Says About Infant Distress',
    content: chance.paragraph({ sentences: 2 }),
    coverImg: s3,
    createdAt: sub(new Date(), {
      days: 5, hours: 2, minutes: 20
    }),
    view: random(9999),
    share: random(9999),
    category: 'Science',
    featured: false,
    author: {
      id: chance.integer({ min: 1, max: 2000 }),
      avatar: user3,
      name: chance.name()
    },
    comments: BlogComment
  },
  {
    id: chance.integer({ min: 1, max: 2000 }),
    title: 'Parental Intuition and Baby Cries: Learning to Interpret Your Baby\'s Needs',
    content: chance.paragraph({ sentences: 2 }),
    coverImg: s4,
    createdAt: sub(new Date(), {
      days: 7, hours: 6, minutes: 20
    }),
    view: random(9999),
    share: random(9999),
    category: 'Parenting Tips',
    featured: false,
    author: {
      id: chance.integer({ min: 1, max: 2000 }),
      avatar: user4,
      name: chance.name()
    },
    comments: BlogComment
  },
  {
    id: chance.integer({ min: 1, max: 2000 }),
    title: 'From Fuss to Quiet: Effective Ways to Comfort Your Crying Baby',
    content: chance.paragraph({ sentences: 2 }),
    coverImg: s5,
    createdAt: sub(new Date(), {
      days: 4, hours: 6, minutes: 20
    }),
    view: random(9999),
    share: random(9999),
    category: 'Parenting Tips',
    featured: false,
    author: {
      id: chance.integer({ min: 1, max: 2000 }),
      avatar: user5,
      name: chance.name()
    },
    comments: BlogComment
  },
  {
    id: chance.integer({ min: 1, max: 2000 }),
    title: 'Colic Chronicles: Navigating the Challenges of Excessive Baby Crying',
    content: chance.paragraph({ sentences: 2 }),
    coverImg: s6,
    createdAt: sub(new Date(), {
      days: 2, hours: 6, minutes: 20
    }),
    view: random(9999),
    share: random(9999),
    category: 'Infant Care',
    featured: false,
    author: {
      id: chance.integer({ min: 1, max: 2000 }),
      avatar: user6,
      name: chance.name()
    },
    comments: BlogComment
  },
  {
    id: chance.integer({ min: 1, max: 2000 }),
    title: 'Baby Crying at Mealtime: Solutions for Feeding-Related Tears',
    content: chance.paragraph({ sentences: 2 }),
    coverImg: s7,
    createdAt: sub(new Date(), {
      days: 3, hours: 6, minutes: 20
    }),
    view: random(9999),
    share: random(9999),
    category: 'Parenting Tips',
    featured: false,
    author: {
      id: chance.integer({ min: 1, max: 2000 }),
      avatar: user2,
      name: chance.name()
    },
    comments: BlogComment
  },
  {
    id: chance.integer({ min: 1, max: 2000 }),
    title: 'The Emotional Impact of Baby Crying on Parents and Families',
    content: chance.paragraph({ sentences: 2 }),
    coverImg: s8,
    createdAt: sub(new Date(), {
      days: 4, hours: 6, minutes: 20
    }),
    view: random(9999),
    share: random(9999),
    category: 'Science',
    featured: false,
    author: {
      id: chance.integer({ min: 1, max: 2000 }),
      avatar: user3,
      name: chance.name()
    },
    comments: BlogComment
  },
  {
    id: chance.integer({ min: 1, max: 2000 }),
    title: 'Why Babies Cry: Understanding the Reasons and How to Soothe Them',
    content: chance.paragraph({ sentences: 2 }),
    coverImg: s9,
    createdAt: sub(new Date(), {
      days: 5, hours: 3, minutes: 20
    }),
    view: random(9999),
    share: random(9999),
    category: 'Infant Care',
    featured: true,
    author: {
      id: chance.integer({ min: 1, max: 2000 }),
      avatar: user4,
      name: chance.name()
    },
    comments: BlogComment
  },
  {
    id: chance.integer({ min: 1, max: 2000 }),
    title: 'Baby Crying in Public: Coping Strategies for Parents',
    content: chance.paragraph({ sentences: 2 }),
    coverImg: s10,
    createdAt: sub(new Date(), {
      days: 0, hours: 1, minutes: 20
    }),
    view: random(9999),
    share: random(9999),
    category: 'Parenting Tips',
    featured: true,
    author: {
      id: chance.integer({ min: 1, max: 2000 }),
      avatar: user5,
      name: chance.name()
    },
    comments: BlogComment
  }
];

mock.onGet('/api/data/blog/BlogPosts').reply(() => {
  return [
    200, BlogPost
  ];
});

// ----------------------------------------------------------------------
mock.onPost('/api/data/blog/post').reply((config: string | any) => {
  try {
    const { title } = JSON.parse(config.data);

    const paramCase = (t: string) =>
      t
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');

    const post = BlogPost.find((_post: blogpostType | string | any) => paramCase(_post.title) === title);

    if (!post) {
      return [
        404, { message: 'Post not found' }
      ];
    }

    return [
      200, { post }
    ];
  } catch (error) {
    console.error(error);

    return [
      500, { message: 'Internal server error' }
    ];
  }
});

mock.onPost('/api/data/blog/post/add').reply((config: string | any) => {
  try {
    const { postId, comment } = JSON.parse(config.data);
    const postIndex = BlogPost.findIndex((x) => x.id === postId);
    const post = BlogPost[postIndex];
    const cComments = post.comments || [];
    post.comments = [
      comment, ...cComments
    ];

    return [
      200, { posts: [...BlogPost] }
    ];
  } catch (err) {
    console.error(err);

    return [
      500, { message: 'Internal server error' }
    ];
  }
});
