import { reactive } from 'vue';

export interface Alert {
  message: string, color: string
}

export interface AlertStore {
  readonly _alert: Alert
  show: (message: string, color?: string) => void
  hide: () => void
}

export const alert: AlertStore = reactive({
  _alert: { message: '', color: '' },
  show(
    message: string,
    color: string = 'error',
    keepMessage: boolean = false
  ) {

    this._alert.message = message;
    this._alert.color = color;

    if(!keepMessage) {
      const FOUR_SECONDS = 4000;

      setTimeout(() => alert.hide(), FOUR_SECONDS);
    }
  },
  hide() {
    this._alert = { message: '', color: '' };
  }
});
