<template>
  <v-alert
    :class="{'show': globalAlert['message']}"
    class="alert"
    :color="globalAlert['color']"
    :text="globalAlert['message']"
    data-test="alert"
  >
    <template #append>
      <v-icon
        icon="mdi-close"
        data-test="alert-close"
        @click="alert.hide()"
      />
    </template>
  </v-alert>
</template>

<script setup lang="ts">
  import { computed } from 'vue';
  import { alert, type Alert } from '@/stores/alert';

  const globalAlert = computed<Alert>(() => alert['_alert']);
</script>

<style lang="scss" scoped>

  $alert-max-width: 600px;
  $alert-min-width: 300px;
  $alert-min-width-mobile: 100px;
  $alert-border-radius: 8px;

  .alert {
    z-index: 99999;
    position: fixed;
    top: -100%;
    right: 0;
    min-width: $alert-min-width;
    max-width: $alert-max-width;
    margin: 16px;
    transition: .6s;
    border-radius: $alert-border-radius;
      &.show {
        top: 0;
    }

    @media (max-width: 600px) {
      width: 90%;
      min-width: $alert-min-width-mobile;
    }
  }
</style>
