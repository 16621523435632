import type { ThemeTypes } from '@/types/themeTypes/ThemeType';

export const MAIN_THEME: ThemeTypes = {
  name: 'MAIN_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
    'border-opacity': 1
  },
  colors: {
    primary: '#244098',
    secondary: '#FF8A73',
    info: '#539BFF',
    success: '#28A745',
    accent: '#FFAB91',
    warning: '#FFC107',
    error: '#DC3545',
    lightprimary: '#CFDAFC',
    primaryVariant: '#6D90FE',
    lightsecondary: '#FFD9D2',
    darksecondary: '#A84E3C',
    darkprimary: '#091B54',
    lightsuccess: '#E6FFFA',
    lighterror: '#FDEDE8',
    lightwarning: '#FEF5E5',
    lightinfo:'#EBF3FE',
    textPrimary: '#2A3547',
    textSecondary: '#2A3547',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#F2F4FA',
    hoverColor: '#f6f9fc',
    surface: '#fff',
    'on-surface-variant': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4',
    hungry: '#FF8A73',
    sleepy: '#091B54',
    needstoburp: '#AFC2FF',
    uncomfortable: '#8A3C2D',
    irritated: '#5072DC'
  }
};

const BLUE_THEME: ThemeTypes = {
  name: 'BLUE_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
    'border-opacity': 1
  },
  colors: {
    primary: '#1e4db7',
    secondary: '#1a97f5',
    info: '#539BFF',
    success: '#05b187',
    accent: '#FFAB91',
    warning: '#FFAE1F',
    error: '#FA896B',
    lightprimary: '#f5fcfd',
    lightsecondary: '#E8F7FF',
    lightsuccess: '#E6FFFA',
    lighterror: '#FDEDE8',
    lightwarning: '#FEF5E5',
    lightinfo:'#EBF3FE',
    textPrimary: '#2A3547',
    textSecondary: '#2A3547',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#f6f6f6',
    hoverColor: '#f6f9fc',
    surface: '#fff',
    'on-surface-variant': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4',
    hungry: '#FFAB91',
    sleepy: '#8fb6ff',
    needstoburp: '#dfd549',
    uncomfortable: '#9fdc79',
    irritated: '#ff8ff6'
  }
};

const RED_THEME: ThemeTypes = {
  name: 'RED_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
    'border-opacity': 1
  },
  colors: {
    primary: '#5e244d',
    secondary: '#ff5c8e',
    info: '#539BFF',
    success: '#05b187',
    accent: '#FFAB91',
    warning: '#FFAE1F',
    error: '#FA896B',
    lightprimary: '#fce6ed',
    lightsecondary: '#EDFBF7',
    lightsuccess: '#E6FFFA',
    lighterror: '#FDEDE8',
    lightwarning: '#FEF5E5',
    lightinfo:'#EBF3FE',
    textPrimary: '#2A3547',
    textSecondary: '#2A3547',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#f6f6f6',
    hoverColor: '#f6f9fc',
    surface: '#fff',
    'on-surface-variant': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4',
    hungry: '#FFAB91',
    sleepy: '#8fb6ff',
    needstoburp: '#dfd549',
    uncomfortable: '#9fdc79',
    irritated: '#ff8ff6'
  }
};

const PURPLE_THEME: ThemeTypes = {
  name: 'PURPLE_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
    'border-opacity': 1
  },
  colors: {
    primary: '#402e8d',
    secondary: '#7352ff',
    info: '#539BFF',
    success: '#05b187',
    accent: '#FFAB91',
    warning: '#FFAE1F',
    error: '#FA896B',
    lightprimary: '#F2ECF9',
    lightsecondary: '#EDF8FA',
    lightsuccess: '#E6FFFA',
    lighterror: '#FDEDE8',
    lightwarning: '#FEF5E5',
    lightinfo:'#EBF3FE',
    textPrimary: '#2A3547',
    textSecondary: '#2A3547',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#f6f6f6',
    hoverColor: '#f6f9fc',
    surface: '#fff',
    'on-surface-variant': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4',
    hungry: '#FFAB91',
    sleepy: '#8fb6ff',
    needstoburp: '#dfd549',
    uncomfortable: '#9fdc79',
    irritated: '#ff8ff6'
  }
};

const GREEN_THEME: ThemeTypes = {
  name: 'GREEN_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
    'border-opacity': 1
  },
  colors: {
    primary: '#066a73',
    secondary: '#00cec3',
    info: '#539BFF',
    success: '#05b187',
    accent: '#FFAB91',
    warning: '#FFAE1F',
    error: '#FA896B',
    lightprimary: '#F4F9FB',
    lightsecondary: '#FAFBEF',
    lightsuccess: '#E6FFFA',
    lighterror: '#FDEDE8',
    lightwarning: '#FEF5E5',
    lightinfo:'#EBF3FE',
    textPrimary: '#2A3547',
    textSecondary: '#2A3547',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#f6f6f6',
    hoverColor: '#f6f9fc',
    surface: '#fff',
    'on-surface-variant': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4',
    hungry: '#FFAB91',
    sleepy: '#8fb6ff',
    needstoburp: '#dfd549',
    uncomfortable: '#9fdc79',
    irritated: '#ff8ff6'
  }
};

const INDIGO_THEME: ThemeTypes = {
  name: 'INDIGO_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
    'border-opacity': 1
  },
  colors: {
    primary: '#11397b',
    secondary: '#1e4db7',
    info: '#539BFF',
    success: '#05b187',
    accent: '#FFAB91',
    warning: '#FFAE1F',
    error: '#FA896B',
    lightprimary: '#EBF9FA',
    lightsecondary: '#FFF5F2',
    lightsuccess: '#E6FFFA',
    lighterror: '#FDEDE8',
    lightwarning: '#FEF5E5',
    lightinfo:'#EBF3FE',
    textPrimary: '#2A3547',
    textSecondary: '#2A3547',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#f6f6f6',
    hoverColor: '#f6f9fc',
    surface: '#fff',
    'on-surface-variant': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4',
    hungry: '#FFAB91',
    sleepy: '#8fb6ff',
    needstoburp: '#dfd549',
    uncomfortable: '#9fdc79',
    irritated: '#ff8ff6'
  }
};

const ORANGE_THEME: ThemeTypes = {
  name: 'ORANGE_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
    'border-opacity': 1
  },
  colors: {
    primary: '#fb9678',
    secondary: '#03c9d7',
    info: '#539BFF',
    success: '#05b187',
    accent: '#FFAB91',
    warning: '#fec90f',
    error: '#fb9678',
    lightprimary: '#e5fafb',
    lightsecondary: '#EFF9FF',
    lightsuccess: '#E6FFFA',
    lighterror: '#FDEDE8',
    lightwarning: '#FEF5E5',
    lightinfo:'#EBF3FE',
    textPrimary: '#2A3547',
    textSecondary: '#2A3547',
    borderColor: '#e5eaef',
    inputBorder: '#DFE5EF',
    containerBg: '#ffffff',
    background: '#f6f6f6',
    hoverColor: '#f6f9fc',
    surface: '#fff',
    'on-surface-variant': '#fff',
    grey100: '#F2F6FA',
    grey200: '#EAEFF4',
    hungry: '#FFAB91',
    sleepy: '#8fb6ff',
    needstoburp: '#dfd549',
    uncomfortable: '#9fdc79',
    irritated: '#ff8ff6'
  }
};

export {
  BLUE_THEME, RED_THEME, ORANGE_THEME, PURPLE_THEME, GREEN_THEME, INDIGO_THEME
};
